import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from "yup";
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import TextArea from '../../components/universal/InputType/TextArea';
import ProgressBar from '../../components/universal/ProgressBar';
import { baseUrlV1, notify } from "../../core/constants";
import { updateGuestUser } from '../../redux/actions/guestUser';
import { addLogin } from '../../redux/actions/login';
import { store } from '../../redux/store';

const BusinessDetails = () => {
    const nav = useNavigate();
    const state = store.getState();
    const { guestDetails } = state.guestReducer;
    const [userDetails, setUserDetails] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { token } = useParams();
    const getUserData = async () => {
        try {
            const res = await axios({
                method: "get",
                url: `${baseUrlV1}user/${id}?deviceType=3&deviceToken=${guestDetails?.token}`,
                headers: { 'Content-Type': 'application/json', userToken: `${token}` },
            })
            const user = res?.data?.result?.user;
            setUserDetails(user);
            formik.setFieldValue("businessName", user?.userVatInformation?.businessName ? user?.userVatInformation?.businessName : "");
            formik.setFieldValue("businessAddress", user?.userVatInformation?.businessAddress ? user?.userVatInformation?.businessAddress : "");
            formik.setFieldValue("businessPhone", user?.userVatInformation?.phoneNumber ? user?.userVatInformation?.phoneNumber : "");
            formik.setFieldValue("businessEmail", user?.userVatInformation?.businessEmail ? user?.userVatInformation?.businessEmail : "");
            formik.setFieldValue("registrationNo", user?.userVatInformation?.registrationNumber ? user?.userVatInformation?.registrationNumber : '');
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getUserData();
    }, []);

    const onSubmit = async (values) => {
        const data = {
            address: [
                {
                    address: userDetails?.address[0]?.address,
                    latitude: userDetails?.address[0]?.latitude,
                    longitude: userDetails?.address[0]?.longitude
                }
            ],
            bio: userDetails?.bio,
            businessAddress: values?.businessAddress,
            businessEmail: values?.businessEmail,
            businessName: values?.businessName,
            businessPhoneNumber: values?.businessPhone,
            name: userDetails?.name,
            //discussed with divya sir that proseller will be handlled by backend. and phone numbe rfield is now correct.
            phoneNumber: userDetails?.userVatInformation?.phoneNumber,
            proSeller:userDetails?.isProSeller,
            registrationNumber: values?.registrationNo,
            userName: userDetails?.userName,
            vatNumber: values?.VAT,
        }
        try {
            const response = await axios({
                method: "post",
                url: `${baseUrlV1}user?deviceType=3&deviceToken=${guestDetails?.token}`,
                data: data,
                headers: { 'Content-Type': 'application/json', userToken: `${token}` },
            })
            if (response.data.statusCode == '102') {
                notify(response.data.message, 'error');
            } else if (response?.data?.result?.user?.isVerified) {
                notify(response.data.message, 'success');
                formik.handleReset();
                let userDetails = {
                    token: response?.data?.result?.userToken,
                    user: response?.data?.result?.user,
                };
                dispatch(addLogin(userDetails));
                let user = response?.data?.result?.user;
                dispatch(
                    updateGuestUser({
                        user: user,
                        userToken: userDetails.token,
                    })
                );
                if (response?.data?.result?.user?.userType == 1) { nav('/admin/categories'); }
                else {
                    nav({
                        pathname: "/onboarding/dashboard",
                        search: `?first=1`,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    // formik start
    const initialValues = {
        businessName: "",
        registrationNo: "",
        VAT: "",
        businessEmail: "",
        businessPhone: "",
        businessAddress: ""
    };
    const validationSchema = Yup.object({
        businessName: Yup.string().required("Business name is required"),
        businessEmail: Yup.string().required('Business email is required').email('Email is invalid'),
        businessPhone: Yup.string()
            .required('Business phone is required')
            .matches(
                /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                'Please provide valid phone'
            )
            .min(10, 'Please provide valid phone'),
        businessAddress: Yup.string().required("Business address is required")
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    useEffect(() => {
        if (formik?.values?.businessName && formik?.values?.businessEmail && formik?.values?.businessPhone && formik?.values?.businessAddress) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }, [formik])

    return (
        <>
            <ProgressBar value={100} />
            <div className='policydetails'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-heading">Business details</div>
                    <div className='text-para'>This information won’t be viewable to customers</div>
                    <div className='mrgbottom'>

                        <div className='label-text'>Business Name</div>
                        <InputTypeOutline
                            name="businessName"
                            id="businessName"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.businessName}
                            onChange={formik.handleChange}
                            error={formik.touched.businessName && formik.errors.businessName}
                        />
                    </div>

                    <div className='mrgbottom'>
                        <div className='label-text'>Company registration number (Optional)</div>
                        <InputTypeOutline
                            name="registrationNo"
                            id="registrationNo"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.registrationNo}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className='mrgbottom'>
                        <div className='label-text'>VAT registration number (Optional)</div>
                        <InputTypeOutline
                            name="VAT"
                            id="VAT"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.VAT}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className='mrgbottom'>
                        <div className='label-text'>Business Email</div>
                        <InputTypeOutline
                            name="businessEmail"
                            id="businessEmail"
                            type="email"
                            onBlur={formik.handleBlur}
                            value={formik.values.businessEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.businessEmail && formik.errors.businessEmail}
                        />
                    </div>

                    <div className='mrgbottom'>
                        <div className='label-text'>Business Phone</div>
                        <InputTypeOutline
                            name="businessPhone"
                            id="businessPhone"
                            type="number"
                            onBlur={formik.handleBlur}
                            value={formik.values.businessPhone}
                            onChange={formik.handleChange}
                            error={formik.touched.businessPhone && formik.errors.businessPhone}
                        />
                    </div>

                    <div className=''>
                        <div className='label-text'>Business Address</div>
                        <TextArea
                            name="businessAddress"
                            id="businessAddress"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.businessAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.businessAddress && formik.errors.businessAddress}
                        />
                    </div>
                    <div className='btn-section'>
                        <div>
                            <LightBtn title="Back" type="button" onClick={() => nav(`/policy-details/${id}/${token}`)} />
                        </div>
                        <div>
                            <DarkBtn title=" Continue " type="submit" disable={disableButton} />

                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BusinessDetails;