import React, { Fragment, useState } from 'react';
import DarkBtn from '../../Buttons/DarkBtn';
import InputTypeOutline from '../../InputType/inputTypeOutline';
import AdminBrandWithCauseDialog from '../../../dialogs/AdminBrandWithCause';
import Pagination from '../../Pagination';
import { useEffect } from 'react';
import BrandWithCauseDropDown from '../../BrandWithCauseDropDown';
import { axiosAll } from '../../../../services/axiosAll';
import { notify } from '../../../../core/constants';
import axios from 'axios';
import CustomTablePagination from '../../TablePagination';

const Table = ({
  sellers,
  data,
  setIsBrandWithCauseUpdated,
  isBrandWithCauseUpdated,
  setPage,
  page,
}) => {
  const [storeValues, setStoreValues] = useState([]);

  useEffect(() => {
    if (data.data) {
      const store = data.data.map((item) => {
        return {
          id: item.id,
          image: item.image,
          storeName: item.storeName,
          sellerId: item.sellerId,
          cause: item.cause,
        };
      });

      setStoreValues(store);
    }
  }, [data]);

  const addBrandWithCause = async (data, id) => {
    const res = await axiosAll({
      url: `v2/brand_with_causes/${id}`,
      method: 'post',
      data: data,
    });
    if (res.statusCode === 200) {
      notify(`${res.message}`, 'success');
      setIsBrandWithCauseUpdated(!isBrandWithCauseUpdated);
    }
  };

  const handleSubmit = async (id, index) => {
    const formData = new FormData();
    const image = storeValues[index].image;
    if (typeof image !== 'string') {
      formData.append('image', image);
    }

    formData.append('storeName', storeValues[index].storeName);
    formData.append('sellerId', storeValues[index].sellerId);
    formData.append('cause', storeValues[index].cause);
    addBrandWithCause(formData, id);
  };

  const deleteBrand = async (id) => {
    const res = await axiosAll({
      url: `v2/brand_with_causes/${id}`,
      method: 'DELETE',
    });

    notify(`${res.message}`, 'success');
    setIsBrandWithCauseUpdated(!isBrandWithCauseUpdated);
  };

  return (
    <div className="table-section">
      <div className="add-row">
        <CustomTablePagination
          page={page}
          count={data?.total}
          onChangePage={(e, newPage) => setPage(newPage)}
        />
        <AdminBrandWithCauseDialog
          sellers={sellers}
          setIsBrandWithCauseUpdated={setIsBrandWithCauseUpdated}
          isBrandWithCauseUpdated={isBrandWithCauseUpdated}
        />
      </div>

      <div className="table-container">
        <table>
          <tr className="heading-name">
            <th>Pro Seller</th>
            <th>Store Name</th>
            <th>Cause</th>
            <th>Add display image </th>
            <th>Action</th>
          </tr>

          {storeValues?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="input">
                  <BrandWithCauseDropDown
                    options={sellers}
                    name={`store[${index}].sellerId`}
                    value={item.sellerId}
                    onChange={(e) => {
                      console.log('index', index);
                      const store = [...storeValues];
                      store[index].sellerId = e.target.value;
                      setStoreValues(store);
                    }}
                  />
                </td>
                <td>
                  <InputTypeOutline
                    placeholder="Store Name"
                    value={item.storeName}
                    name={`storeName${index}`}
                    onChange={(e) => {
                      const store = [...storeValues];
                      store[index].storeName = e.target.value;
                      setStoreValues(store);
                    }}
                  />
                </td>
                <td>
                  <InputTypeOutline
                    placeholder="Cause"
                    value={item.cause}
                    name={`cause${index}`}
                    onChange={(e) => {
                      const store = [...storeValues];
                      store[index].cause = e.target.value;
                      setStoreValues(store);
                    }}
                  />
                </td>
                <td>
                  <div>
                    <div className="fileuploadsection">
                      <div className="file-section">
                        <button type="button" className="attach-btn">
                          <label for={`files${index}`}>
                            Choose File <span className="plusicon">+</span>
                          </label>
                        </button>
                        <input
                          id={`files${index}`}
                          className="input-type-file-upload"
                          type="file"
                          name={`store[${index}].image`}
                          onChange={(e) => {
                            console.log('index', index);
                            const store = [...storeValues];
                            store[index].image = e.target.files[0];
                            setStoreValues(store);
                          }}
                        />
                      </div>
                    </div>
                    <div className="product-img">
                      {/* <img src={item.image} /> */}
                      {/* if image type is string then show item.image if file type then file */}
                      {typeof item.image === 'string' ? (
                        <img src={item.image} />
                      ) : (
                        <img src={URL.createObjectURL(item.image)} />
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <DarkBtn
                    title="Submit"
                    onClick={() => handleSubmit(item.id, index)}
                  />
                  <DarkBtn
                    title="Delete"
                    onClick={() => deleteBrand(item.id)}
                  />
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default Table;
