import toast from "react-hot-toast";
import currentURL from "../env";
// export const baseUrl = 'https://prod.goethical.com/api/v2/'
export const baseUrl = `${currentURL.currentEnv}`;
export const baseUrlV1 = `${currentURL.currentEnv.replace("v2/", "v1/")}`;
export const deviceToken = "a75a8f6c-80c4-4ced-a5d8-80b821f7dfbc";

export const path = {
  homeData: "home/getHomeScreenData",
  feedBack: "feedback-store?",
  signup: "user/signup?",
  login: "user/login",
};

export const getScreenSize = () => {
  if (window.innerWidth <= 450) {
    return "mobile";
  } else if (window.innerWidth <= 768) {
    return "tablet";
  } else {
    return "web";
  }
};

export const dynamicContent = (
  deviceSize,
  property,
  mobile,
  tablet,
  web,
  defaultSize
) => {
  if (deviceSize === "mobile") {
    if (mobile?.[property]) {
      return mobile[property];
    } else {
      return defaultSize[property];
    }
  } else if (deviceSize === "tablet") {
    if (tablet?.[property]) {
      return tablet[property];
    } else {
      return defaultSize[property];
    }
  } else if (deviceSize === "web") {
    if (web?.[property]) {
      return web[property];
    } else {
      return defaultSize[property];
    }
  } else {
    return defaultSize[property];
  }
};

export const notify = (message, type) => {
  toast(`${message}`, {
    type: `${type}`,
    position: "bottom-center",
    borderRadius: "2px",
    background: "#333",
    color: "#fff",
    autoClose: 4000,
  });
};

export const category = [
  {
    title: "All",
    id: "0",
  },
  {
    title: "Ethical",
    id: "44",
  },
  {
    title: "Artisan",
    id: "295",
  },
  {
    title: "Preloved",
    id: "42",
  },
];

export const sortBy = [
  {
    name: "Most recent",
    value: 0,
  },
  {
    name: "Highest priced",
    value: 2,
  },
  {
    name: "Lowest priced",
    value: 1,
  },
];

export const doesStoreRunEcommerce = [
  {
    title: "Does your store run on an e-commerce system, eg.Shopify",
    value: "",
  },
  {
    title: "Yes",
    value: "Yes",
  },
  {
    title: "No",
    value: "No",
  },
];

export const marketPlace = [
  {
    title: "Do you sell on any existing marketplaces?",
    value: "",
  },
  {
    title: "Yes",
    value: "Yes",
  },
  {
    title: "No",
    value: "No",
  },
];

export const ReachOptions = [
  {
    title: "Where did you hear about us?",
    value: "",
  },
  {
    title: "Google Search",
    value: "Google Search",
  },
  {
    title: "Local Advertising",
    value: "Local Advertisinng",
  },
  {
    title: "Facebook",
    value: "Facebook",
  },
  {
    title: "Twitter",
    value: "Twitter",
  },
  {
    title: "Word of mouth",
    value: "Word of mouth",
  },
  {
    title: "Directly from GoEthical",
    value: "Directly from Ethical",
  },
];

export const TraderTypeOption = [
  {
    title: "What Type of trader are you ?",
    value: "",
  },
  {
    title: "Brand",
    value: "Brand",
  },
  {
    title: "Oraganization",
    value: "Oraganization",
  },
  {
    title: "Charity",
    value: "Carity",
  },
  {
    title: "Individual",
    value: "Individual",
  },
];

export const Business = [
  {
    title: "Do you have a website for your business?",
    value: "",
  },
  {
    title: "Yes",
    value: "Yes",
  },
  {
    title: "No",
    value: "No",
  },
];

export const IsRegistered = [
  {
    title: "Are you a Registred Limited Company*",
    value: "",
  },
  {
    title: "Yes",
    value: "Yes",
  },
  {
    title: "No",
    value: "No",
  },
  {
    title: "Not Sure",
    value: "Not Sure",
  },
];

export const VAT = [
  {
    title: "Are you UK VAT registred",
    value: "",
  },
  {
    title: "Yes",
    value: "Yes",
  },
  {
    title: "No",
    value: "No",
  },
  {
    title: "Not Sure",
    value: "Not Sure",
  },
];

export const OrderOption = [
  {
    title: "All statuses",
    id: "0",
  },
  {
    title: "Pending",
    id: "1",
  },
  {
    title: "Dispatched",
    id: "2",
  },
  {
    title: "Cancelled",
    id: "3",
  },
  {
    title: "Disputed",
    id: "4",
  },
  {
    title: "Partial Refund",
    id: "5",
  },
  {
    title: "Pending Payment",
    id: "6",
  },
  {
    title: "Failed Payment",
    id: "7",
  },
  {
    title: "Completed Order",
    id: "8",
  },
];

export const UserRegistrationOption = [
  {
    title: "Bulk Actions",
    value: "1",
  },
  {
    title: "Delete",
    value: "2",
  },
  {
    title: "Allow user -access to Dashboard and to App",
    value: "3",
  },
  {
    title: "Deny user -No access to Dashboard and to App",
    value: "4",
  },
  {
    title: "Deny user Dashboard  Access -but continue access to App",
    value: "5",
  },
];

export const ProductCondition = [
  {
    title: "Product Condition",
    value: "",
  },
  {
    title: "New",
    value: "1",
  },
  {
    title: "Very Good",
    value: "0",
  },
  {
    title: "Worn",
    value: "2",
  },
];

export const countriesFrom = {
  name: "Country you're dispatching from",
  id: "0",
  inSubsection:true
};

export const ProcessigTime = {
  title: "Select Processig Time",
  value: "0",
  inSubsection:true
};

export const DeliveryCourier = {
  title: "Select Delivery Courier",
  value: "0",
  inSubsection:true
};

export const DeliveryClass = {
  title: "Select Delivery Class",
  value: "0",
  inSubsection:true
};

export const productValue = {
  title: "Product Value",
  value: "0",
  inSubsection:true
};

export const colorName = {
  title: "Product Colour",
  value: "0",
  inSubsection:true
};

export const capitalize = (s) => {
  return s?.charAt(0).toUpperCase() + s.slice(1);
};
export const TRACKING_ID = "G-51GB78DR7P"; // OUR_TRACKING_ID

// capitalize first letter of every word
export const capitalizeFirstLetter = (s) => {
  return s?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
