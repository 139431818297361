import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as React from 'react';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import DropDown from '../../universal/DropDown';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

export default function RefundDialog({ OrdersList, setOpenUp }) {
  const [open, setOpen] = React.useState(false);
  const [amount, setamount] = React.useState(1);
  const [status, setStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setamount('');
  };
  const handleStatus = (e) => {
    const { value } = e.target;
    setStatus(value);
  };

  const handleRefund = async () => {
    if (status == 0) {
      notify('Please select product from the list.', 'error');
    } else {
      const Refunddata = {
        order_item_id: status,
        price: amount,
      };
      setLoading(true);
      const responce = await axiosAll({
        method: 'post',
        url: `v2/order/order-refund`,
        data: Refunddata,
      });
      console.log(responce?.status);
      if (responce?.status == 'Success') {
        notify(responce?.message, 'success');
        setLoading(false);
        setamount('');
        handleClose();
        update();
      } else {
        notify(responce?.message, 'error');
        setLoading(false);
      }
    }
  };

  const update = () => {
    setOpenUp(true);
  };

  return (
    <div className="removefrom">
      <Button className="darkbtn" onClick={handleClickOpen}>
        Refund
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="refund"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="input-heading">
              <h2 className="upload-text">Refund</h2>
              <div className="label-text">Product list</div>
              <DropDown
                option={OrdersList}
                handlechan={handleStatus}
                value={status}
              />
              <div className="label-text">Refund amount (£)</div>
              <InputTypeOutline
                value={amount}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== '.') {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                type="number"
                min="1"
              />
              <div className="btn-section-refund">
                <LightBtn title="Cancel" onClick={handleClose} />
                <DarkBtn
                  onClick={() => handleRefund()}
                  title="Submit"
                  loading={loading}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
