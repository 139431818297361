import React from 'react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { notify } from '../../../../core/constants';
const CodeInputBox = ({ code, productCondition }) => {
  const handleClickCopy = (code) => {
    navigator.clipboard.writeText(code);
    notify('Copy this text to clipboard', 'success');
  };

  return (
    <div className="codeinputbox">
      <div className="inputwithicon">
        <input
          type="text"
          className="code-input"
          value={code == '0' ? (productCondition ? code : '') : code}
        />
        <div>
          <button class="icon-row" onClick={() => handleClickCopy(code)}>
            <BookmarkBorderIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodeInputBox;
