import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notify } from '../../../core/constants';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

export default function WelcomeAdminDialog({
  handleChange,
  accountData,
  onclick,
  loading,
  setLoading,
  bankAccountModal,
  setBankAccountModal,
}) {
  const handleClickOpen = () => {
    setBankAccountModal(true);
  };

  const handleClose = () => {
    setBankAccountModal(false);
  };

  // formik start

  const initialValues = {
    fullName: accountData?.fullName,
    accountNumber: accountData?.accountNumber,
    bsb: accountData?.bsb,
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required('full name is required.'),
    accountNumber: Yup.string().required('account number is required.'),
    bsb: Yup.string().required('sort code is required.'),
  });

  const onSubmit = (values) => {
    onSubmitAccount(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onSubmitAccount = (values) => {
    const trimmedName = values?.fullName?.trim();
    if (trimmedName.indexOf(' ') == -1) {
      notify('Please enter a valid full name', 'error');
    } else {
      onclick(values);
    }
  };

  return (
    <div>
      {/* <LightBtn onClick={handleClickOpen}/> */}
      <Button
        variant="contained"
        className="lightbtn"
        onClick={handleClickOpen}
      >
        Update
      </Button>
      <Dialog
        open={bankAccountModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="updatebankdetails"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <SubHeading title="Update Bank Details" />
            <div className="tracking-text "> Account holder name</div>

            <InputTypeOutline
              type={'text'}
              name={'fullName'}
              onChange={formik.handleChange}
              value={formik.values.fullName}
              error={formik.errors.fullName}
            />

            <div className="tracking-text ">Account Number</div>
            <InputTypeOutline
              type={'text'}
              name={'accountNumber'}
              onChange={formik.handleChange}
              value={formik.values.accountNumber}
              error={formik.errors.accountNumber}
            />
            <div className="tracking-text ">Sort Code</div>
            <InputTypeOutline
              type={'text'}
              name={'bsb'}
              onChange={formik.handleChange}
              value={formik.values.bsb}
              error={formik.errors.bsb}
            />
            <div className="btn-section">
              <LightBtn title="Cancel" onClick={handleClose} />
              <DarkBtn
                title="Submit"
                type="submit"
                disabled={loading ? true : false}
                loading={loading}
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
